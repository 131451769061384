<template>
  <base-section
    id="pro-features2"
  >
    <base-section-heading title="Удаления учетных данных из системы" />
    <v-container style="font-size: large">
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
        >
          Для удаления Ваших учетных данных из системы, выполните следующие действия:
          <ul>
            <li>
              1.⁠ ⁠Обратитесь по телефону горячей линии: <a href="tel:8(800)333-30-84">8(800)333-30-84</a> (звонок по России бесплатный).
            </li>
            <li>
              2.⁠ ⁠Представьтесь и запросите удаление Ваших учетных данных.
            </li>
            <li>3.⁠ ⁠Будьте готовы предоставить необходимую информацию для подтверждения личности.</li>
            <li>4.⁠ ⁠Следуйте инструкциям оператора по удалению Ваших учетных данных из системы.</li>
          </ul>
          Если у вас возникнут дополнительные вопросы или затруднения, обратитесь по телефону горячей линии:
          <a href="tel:8(800)333-30-84">8(800)333-30-84</a> (звонок по России бесплатный) или напишите письмо на адрес
          <a href="mailto:support@itmuse.ru">support@itmuse.ru</a>.
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProFeat',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>
